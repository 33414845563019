import { css } from '@emotion/react';
import { FreeTextSubmission, FreeTextQuestion as IFreeTextQuestion } from '__generated__/graphql';
import { QuestionHeading } from './question-heading';
import { Textarea } from '@mantine/core';
import { useCallback, useEffect } from 'react';
import { FormUtils } from './form-utils';
import { useFormContext } from 'react-hook-form';
import { Correction } from './correction';

interface Props {
    question: IFreeTextQuestion;
    showCorrect?: boolean;
    overrideSelection?: FreeTextSubmission;
}

export function FreeTextQuestion(props: Props) {
    const { showCorrect, question, overrideSelection } = props;
    const questionId = `FreeTextQuestion.q-${question.id}`;
    const methods = useFormContext();
    const { register, watch, setValue: formSetValue } = methods;
    const value = watch(`${questionId}.answer`);

    const setValue = useCallback((val: string) => formSetValue(`${questionId}.answer`, val), [formSetValue]);

    useEffect(() => {
        FormUtils.registerQuestion({
            questionId: questionId,
            questionType: 'FreeTextQuestion',
            register
        });
    }, [questionId, register]);

    useEffect(() => {
        if (!!overrideSelection) {
            setValue(overrideSelection.grading?.submittedStudentAnswerText as string);
        }
    }, [overrideSelection, questionId, setValue]);

    return (
        <div
            css={css`
                display: flex;
                flex-direction: column;
                gap: 0.5rem;
            `}
        >
            {question.clinicalNotes && <QuestionHeading>{question.clinicalNotes}</QuestionHeading>}
            <QuestionHeading showCorrect={showCorrect} isCorrect={overrideSelection?.grading?.result === 'CORRECT'}>
                {question.text}
            </QuestionHeading>
            <Textarea readOnly={showCorrect} onChange={(e) => setValue(e.target.value)} value={value} />
            {showCorrect && <Correction>{overrideSelection?.grading?.reason}</Correction>}
        </div>
    );
}
