import { supabase } from 'config/supabase';
import { css } from '@emotion/react';
import { Auth } from '@supabase/auth-ui-react';
import { ThemeSupa, ViewType } from '@supabase/auth-ui-shared';
import { useLocation } from 'react-router';

export function SignUpPage() {
    const location = useLocation();
    const isJoin = new URLSearchParams(location.search).get('mode') === 'join';
    const isReset = new URLSearchParams(location.search).get('reset') === 'true';

    const getMode = (): ViewType => {
        if (isReset) {
            return 'update_password';
        }

        if (isJoin) {
            return 'sign_up';
        }

        return 'sign_in';
    };

    return (
        <div
            css={css`
                width: 100%;
                height: 100%;
                display: flex;
                justify-content: center;
            `}
        >
            <div
                css={css`
                    height: fit-content;
                    width: fit-content;
                    min-width: 28rem;
                    margin-top: max(5%, 3rem);
                    padding: 1rem 2rem;
                    border: 1px solid #dbdbdb;
                    border-radius: 12px;

                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    gap: 1.5rem;
                `}
            >
                <div
                    css={css`
                        height: 6rem;
                    `}
                >
                    <img
                        src="/images/cctc-logo.png"
                        css={css`
                            height: auto;
                            width: auto;
                            max-width: 100%;
                            max-height: 100%;
                        `}
                    />
                </div>
                <div
                    css={css`
                        width: 100%;
                    `}
                >
                    <Auth
                        supabaseClient={supabase}
                        appearance={{
                            theme: ThemeSupa,
                            variables: {
                                default: {
                                    colors: {
                                        brand: '#ad0d2d',
                                        brandAccent: '#ad0d2d'
                                        // inputBorder: 'black',
                                        // defaultButtonBorder: 'black',
                                        // defaultButtonText: 'black',
                                        // inputText: 'black',
                                        // inputLabelText: 'black'
                                    }
                                }
                            }
                        }}
                        providers={['google']}
                        view={getMode()}
                    />
                </div>
            </div>
        </div>
    );
}

export default SignUpPage;
