import { createHttpLink, ApolloClient, from, InMemoryCache } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { supabase } from './supabase';

const authLink = setContext(async (_, { headers }) => {
    const token = (await supabase.auth.getSession()).data.session?.access_token;

    return {
        headers: {
            ...headers,
            ...(token ? { authorization: `Bearer ${token}` } : null)
        }
    };
});

const httpLink = createHttpLink({
    uri: import.meta.env.VITE_APP_GRAPHQL_API_URL
});

const errorLink = onError((args) => {
    console.log('errorLink', args);
    const { graphQLErrors } = args;

    if (graphQLErrors) {
        for (const err of graphQLErrors) {
            const { extensions } = err;
            // @ts-expect-error idk
            const { code } = extensions;

            if (code === 'UNAUTHORIZED') {
                supabase.auth.signOut();
            }
        }
    }
});

export const client = new ApolloClient({
    link: from([errorLink, authLink, httpLink]),
    cache: new InMemoryCache()
});
