import './tour.css';
import { useQuery } from '@apollo/client';
import { css } from '@emotion/react';
import { Button, Center, LoadingOverlay, Modal } from '@mantine/core';
import { useFragment } from '__generated__';
import { STUDY_REVIEW_BY_STUDY_ID, STUDY_WITH_ANSWERS_BY_ID, STUDY_WITH_ANSWERS_FRAGMENT } from 'graphql/queries';
import { supabase } from 'config/supabase';
import { QuestionsForm } from 'pages/Authed/CasePage/components/QuestionsDrawer';
import { DumbQuestionDrawer } from 'pages/Authed/CasePage/components/QuestionsDrawer/dumb-question-drawer';
import { DumbCasePage } from 'pages/Authed/CasePage/dumb-case-page';
import { useGetStudy } from 'pages/Authed/CasePage/use-get-study';
import { DumbCaseReviewPage } from 'pages/Authed/CaseReviewPage/dumb-case-review-page';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { StudyUtils } from 'utils/study-utils';
import { tour } from './tour';
import { FeatureFlag, getFeatureFlag } from 'config/feature-flag';
import KeyboardDoubleArrowDownIcon from '@mui/icons-material/KeyboardDoubleArrowDown';

export function DemoPage() {
    const [token, setToken] = useState<string>();
    const study = useGetStudy({
        studyId: Number(import.meta.env.VITE_APP_DEMO_STUDY_ID),
        queryOptions: {
            skip: !token
        }
    });
    const formMethods = useForm<QuestionsForm>({ reValidateMode: 'onChange' });
    const { data: studyWithAnswers } = useQuery(STUDY_WITH_ANSWERS_BY_ID, {
        variables: { id: study?.studyData?.id ?? -1 },
        skip: !token || !study?.studyData?.id,
        fetchPolicy: 'network-only'
    });
    const { data: reviewData } = useQuery(STUDY_REVIEW_BY_STUDY_ID, {
        variables: { id: study?.studyData?.id ?? -1 },
        skip: !token || !study?.studyData?.id,
        fetchPolicy: 'network-only'
    });
    const [showCorrect, setShowCorrect] = useState(false);
    const [showReview, setShowReview] = useState(false);
    const [drawerOpen, setDrawerOpen] = useState(false);
    const [quickstartOpen, setQuickstartOpen] = useState(true);
    const questionsDrawerMode = getFeatureFlag('QuestionsDrawerUX');
    console.log({ questionsDrawerMode });
    const studyDataForRender = useFragment(STUDY_WITH_ANSWERS_FRAGMENT, studyWithAnswers?.study) ?? study?.studyData;

    const onSubmit = () => {
        console.log('submitting demo');
        return new Promise((resolve) => setTimeout(() => resolve(setShowCorrect(true)), 1500));
    };

    useEffect(() => {
        const thing = async () => {
            const user = await supabase.auth.signInWithPassword({
                email: import.meta.env.VITE_APP_DEMO_USER_EMAIL,
                password: import.meta.env.VITE_APP_DEMO_USER_PASSWORD
            });

            const _token = user.data.session?.access_token;
            if (_token) {
                setToken(_token);
            }
        };

        thing();
    }, []);

    useEffect(() => {
        window.addEventListener('message', (ev) => {
            if (ev.origin !== import.meta.env.VITE_APP_DICOM_BASE_URL) {
                return;
            }

            console.log(ev.origin, ev.data);

            if (ev.data === 'tour-done') {
                tour.start();
            }
        });
    }, []);

    useEffect(() => {
        tour.on('show', (e: any) => {
            console.log('onshow', e);
            if (e?.step?.id === 'questions' && !drawerOpen) {
                setDrawerOpen(true);
            }
        });
    }, [drawerOpen]);

    if (!token || !studyDataForRender || !reviewData?.study?.review) {
        return <LoadingOverlay visible />;
    }

    const studyInstanceUid = studyDataForRender.dicomStudyUid;
    const ohifUrl = StudyUtils.getOhifUrl({ studyInstanceUid, token });

    const onQuckstartClose = () => {
        setQuickstartOpen(false);
        setDrawerOpen(true);
    };

    const onStartTour = () => {
        const iframe = document.querySelector('iframe')!;
        console.log('POST sending message to ohif: tour');
        iframe.contentWindow?.postMessage('start-tour', import.meta.env.VITE_APP_DICOM_BASE_URL);

        setQuickstartOpen(false);
    };

    const onShowQuestions = () => {
        setDrawerOpen(true);
        if (tour.isActive()) {
            tour.next();
        }
    };

    const renderCase = () => {
        if (questionsDrawerMode === FeatureFlag.QuestionsDrawerUX.SPLIT_SCREEN) {
            return (
                <div
                    css={css`
                        display: flex;
                        height: 100vh;
                        overflow-y: hidden;
                        background-color: black;

                        position: relative;
                    `}
                >
                    <div
                        css={css`
                            flex: 1;
                        `}
                    >
                        <DumbCasePage studyForRender={studyDataForRender} ohifUrl={ohifUrl} />
                    </div>
                    <DumbQuestionDrawer
                        submission={null}
                        showCorrect={showCorrect}
                        currentStudy={studyDataForRender}
                        open={drawerOpen}
                        onClose={() => setDrawerOpen(false)}
                        onOpen={() => setDrawerOpen(true)}
                        formMethods={formMethods}
                        onSubmit={onSubmit}
                        onGoToReview={() => setShowReview(true)}
                        sidebarProps={{ colour: 'black' }}
                    />
                </div>
            );
        } else if (questionsDrawerMode === FeatureFlag.QuestionsDrawerUX.SCROLL) {
            return (
                <div
                    css={css`
                        display: flex;
                        flex-direction: column;
                        min-height: 100vh;
                        background-color: black;
                        position: relative;

                        align-items: center;
                    `}
                >
                    <div
                        css={css`
                            height: 85vh;
                            width: 100%;
                            overscroll-behavior: contain;
                        `}
                    >
                        <DumbCasePage studyForRender={studyDataForRender} ohifUrl={ohifUrl} />
                    </div>
                    <div
                        css={css`
                            display: flex;
                            flex-direction: column;
                            align-items: center;
                            color: white;
                            z-index: 999999;
                            padding: 2rem;
                        `}
                    >
                        <KeyboardDoubleArrowDownIcon
                            css={css`
                                stroke: white;
                                fill: white;
                                font-size: 2rem;
                            `}
                        />
                        <span>Scroll down to answer questions</span>
                    </div>
                    <div
                        css={css`
                            display: flex;
                            justify-content: center;
                            width: 100%;
                            background-color: white;
                        `}
                    >
                        <DumbQuestionDrawer
                            submission={null}
                            showCorrect={showCorrect}
                            currentStudy={studyDataForRender}
                            open={drawerOpen}
                            onClose={() => setDrawerOpen(false)}
                            onOpen={() => setDrawerOpen(true)}
                            formMethods={formMethods}
                            onSubmit={onSubmit}
                            onGoToReview={() => setShowReview(true)}
                            sidebarProps={{ colour: 'black' }}
                        />
                    </div>
                </div>
            );
        } else {
            return (
                <>
                    <div
                        css={css`
                            margin-bottom: 1rem;
                            margin-right: 1rem;
                            display: flex;
                            justify-content: flex-end;
                            gap: 1rem;
                        `}
                    >
                        <Button variant="light" onClick={() => setQuickstartOpen(true)}>
                            Show Quickstart Guide
                        </Button>
                        <Button onClick={onShowQuestions} data-tour="show-questions">
                            Show Case Questions
                        </Button>
                    </div>
                    <div
                        css={css`
                            height: 100%;
                            position: relative;
                        `}
                    >
                        <DumbQuestionDrawer
                            submission={null}
                            showCorrect={showCorrect}
                            currentStudy={studyDataForRender}
                            open={drawerOpen}
                            onClose={() => setDrawerOpen(false)}
                            onOpen={() => setDrawerOpen(true)}
                            formMethods={formMethods}
                            onSubmit={onSubmit}
                            onGoToReview={() => setShowReview(true)}
                            sidebarProps={{ colour: 'black' }}
                        />
                        <div
                            css={css`
                                height: 100%;
                                width: calc(100% - 3rem);
                            `}
                        >
                            <DumbCasePage token={token} studyForRender={studyDataForRender} ohifUrl={ohifUrl} />
                        </div>
                    </div>
                </>
            );
        }
    };

    return (
        <div
            css={css`
                height: 95vh;
                /* padding: 2rem; */
            `}
        >
            {quickstartOpen && (
                <Modal
                    opened={quickstartOpen}
                    onClose={onQuckstartClose}
                    centered
                    title="Demo Quickstart Guide"
                    size="lg"
                >
                    <ul>
                        <li>Click-and-drag to stack-scroll through the images</li>
                        <li>Use the tool menu at the top of the DICOM viewer to switch tools</li>
                        <li>Double click different views in the left panel to switch to a different view</li>
                        <li>
                            Answer the case questions, then click "submit" when finished, you will see the graded
                            questions and have the option to view the correlated angiogram
                        </li>
                    </ul>
                    <div>
                        You can view this guide any time by clicking on the "Show Quickstart Guide" button at the top of
                        this page.
                    </div>
                    <Center
                        mt="lg"
                        css={css`
                            gap: 1rem;
                        `}
                    >
                        <Button variant="light" onClick={onQuckstartClose}>
                            Got it
                        </Button>
                        {/* <Button onClick={onStartTour}>View interactive Guide</Button> */}
                    </Center>
                </Modal>
            )}
            {!showReview ? (
                renderCase()
            ) : (
                <div
                    css={css`
                        padding: 0 2rem;
                        padding-bottom: 2rem;
                    `}
                >
                    <DumbCaseReviewPage
                        token={token}
                        review={reviewData.study.review}
                        onBackToStudy={() => setShowReview(false)}
                    />
                </div>
            )}
        </div>
    );
}
