import { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { PredictiveAnswer, PredictiveQuestion } from '__generated__/graphql';
import { Autocomplete, Checkbox } from '@mantine/core';
import { css } from '@emotion/react';
import { QuestionHeading } from './question-heading';
import { Correction } from './correction';
import { FormUtils } from './form-utils';

interface Props {
    question: PredictiveQuestion;
    showCorrect?: boolean;
    overrideSelection?: PredictiveAnswer['id'];
}

export default function PredictiveTextQuestion(props: Props) {
    const { showCorrect, question, overrideSelection } = props;
    const { id, text, possibleAnswers, correctAnswers } = question;
    const methods = useFormContext();
    const { register, setValue, watch, formState, getFieldState } = methods;
    const disabled = formState.isSubmitting || showCorrect;
    const [inputText, setInputText] = useState<string>();

    const questionId = `PredictiveQuestion.q-${id}`;

    useEffect(() => {
        FormUtils.registerQuestion({ questionId, questionType: 'PredictiveQuestion', register });
    }, [questionId, register]);

    useEffect(() => {
        if (typeof overrideSelection === 'number') {
            setValue(`${questionId}.answer`, overrideSelection);

            const ans = question.possibleAnswers?.find((a) => a.id === overrideSelection);
            if (ans) {
                setInputText(ans.text);
            }
        }
    }, [overrideSelection, question.possibleAnswers, questionId, setValue]);

    const onChange = (val: string) => {
        const selectedAnswer = possibleAnswers?.find((a) => String(a.id) === val);

        if (selectedAnswer) {
            setValue(`${questionId}.answer`, val);
        } else {
            setValue(`${questionId}.answer`, null);
        }
    };

    const onBlur = (e: React.FocusEvent<HTMLInputElement, Element>) => {
        const val = e.target.value;

        if (typeof val === 'string' && !possibleAnswers?.find((a) => a.text === val)) {
            setValue(`${questionId}.answer`, null);
            setInputText('');
        }
    };

    const handleNoneOfAbove = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.currentTarget.checked) {
            setValue(`${questionId}.answer`, 'none-of-the-above');
        } else {
            setValue(`${questionId}.answer`, null);
            setInputText('');
        }
    };

    const selectedAnswerId = watch(`${questionId}.answer`);

    return (
        <div
            css={css`
                display: flex;
                flex-direction: column;
                gap: 0.5rem;
            `}
        >
            <QuestionHeading
                showCorrect={showCorrect}
                isCorrect={
                    !!correctAnswers?.find((ca) => ca.id === Number(selectedAnswerId)) ||
                    (selectedAnswerId === 'none-of-the-above' && correctAnswers?.length === 0)
                }
                error={!!getFieldState(questionId).error}
                errorText={FormUtils.getErrorMessage({ questionId, getFieldState })}
            >
                {text}
            </QuestionHeading>
            <div
                css={css`
                    display: flex;
                    align-items: flex-end;
                    gap: 2rem;
                    width: 100%;
                `}
            >
                <Autocomplete
                    label="Answer"
                    placeholder="Begin typing to see suggestions"
                    data={possibleAnswers?.map((a) => ({ value: String(a.id), label: a.text })) ?? ['a', 'b']}
                    onOptionSubmit={onChange}
                    onBlur={onBlur}
                    value={inputText}
                    onChange={(v) => setInputText(v)}
                    disabled={selectedAnswerId === 'none-of-the-above' || disabled}
                    styles={{
                        dropdown: {
                            zIndex: 9999999
                        }
                    }}
                    css={css`
                        flex: 1 1 0;
                    `}
                />
                <Checkbox
                    label="No non-coronary findings"
                    onChange={handleNoneOfAbove}
                    disabled={disabled}
                    css={css`
                        padding-bottom: 8px;
                        padding-right: 1rem;
                    `}
                />
            </div>

            {showCorrect && (
                <Correction>
                    <div>
                        {correctAnswers?.length ? (
                            <div>Correct selection(s): {correctAnswers?.map((a) => a?.text).join(', ')}</div>
                        ) : (
                            <div>The correct selection is 'None of the above'</div>
                        )}
                    </div>
                </Correction>
            )}
        </div>
    );
}
