import 'index.css';
import React from 'react';
import '@mantine/core/styles.css';
import '@mantine/carousel/styles.css';
import { Router } from 'react-router';
import history from 'config/history';
import CssBaseLine from '@mui/material/CssBaseline';
import App from './layouts/App';
import theme from 'config/mui-theme';
import { ThemeProvider } from '@mui/material/styles';
import { ApolloProvider } from '@apollo/client';
import { createRoot } from 'react-dom/client';
import { MantineProvider } from '@mantine/core';
import { loadInspectlet } from 'config/load-inspectlet';
import { rudderAnalytics } from 'utils/analytics';
import { PostHogProvider } from 'posthog-js/react';
import { PostHogConfig } from 'posthog-js';
import { client } from 'config/apollo';

loadInspectlet();
rudderAnalytics.load(
    import.meta.env.VITE_APP_RUDDERSTACK_WRITE_KEY,
    import.meta.env.VITE_APP_RUDDERSTACK_DATA_PLANE_URL,
    {}
);

const posthogEnabled = window.location.host !== 'localhost:5173';
console.log({ posthogEnabled });

const options: Partial<PostHogConfig> = {
    autocapture: posthogEnabled,
    disable_session_recording: !posthogEnabled,
    ui_host: 'https://us.i.posthog.com',
    api_host: import.meta.env.VITE_APP_PUBLIC_POSTHOG_PROXY
};

const container = document.getElementById('root');
const root = createRoot(container!);
root.render(
    <React.StrictMode>
        <ThemeProvider theme={theme}>
            <MantineProvider>
                <Router history={history}>
                    <ApolloProvider client={client}>
                        <PostHogProvider apiKey={import.meta.env.VITE_APP_PUBLIC_POSTHOG_KEY} options={options}>
                            <CssBaseLine />
                            <App />
                        </PostHogProvider>
                    </ApolloProvider>
                </Router>
            </MantineProvider>
        </ThemeProvider>
    </React.StrictMode>
);
