import { useState, useRef, useEffect } from 'react';
import Authed from '../Authed';
import Public from '../Public';
import { ThemeProvider } from 'styled-components';
import { lightTheme, GlobalStyles } from 'config/themes';
import { useGlobalStore } from 'store/zustand/store';
import { supabase } from 'config/supabase';
import { useHistory, useLocation } from 'react-router';
import routes from 'config/routes';
import { rudderAnalytics } from 'utils/analytics';

export default function App() {
    const [devAuthed, setDevAuthed] = useState<boolean>(false);
    const passwordRef = useRef<HTMLInputElement>(null);
    const [currentUserId, setCurrentUserId] = useGlobalStore((state) => [state.currentUserId, state.setCurrentUserId]);
    const history = useHistory();
    const location = useLocation();
    console.log('cuid', currentUserId);

    const isUserAuthed = !!currentUserId;

    const login = () => {
        if (passwordRef.current?.value === 'cardiacproj12!!') {
            setDevAuthed(true);
        }
    };

    useEffect(() => {
        const sub = supabase.auth.onAuthStateChange((event, sess) => {
            console.log('onAuthStateChange', event, sess);

            if (sess?.user.email === 'admin+publicdemo@thecardiacctcourse.com') {
                return;
            }

            if (event === 'PASSWORD_RECOVERY') {
                console.log('PasswordRecovery event');
                history.push(`${routes.PUBLIC.SIGNUP_PAGE.url}?reset=true`);
            }

            if (history.location.search.includes('reset=true') && event !== 'SIGNED_IN') {
                return;
            }

            if (event === 'SIGNED_IN' || event === 'INITIAL_SESSION') {
                console.log('cuid1', currentUserId);
                console.log({ event });
                console.log({ sess, currentUserId });

                if (!!sess?.user.id) {
                    rudderAnalytics.identify(sess.user.id, { email: sess.user.email });
                }

                // if new session user !== existing session user
                if (sess?.user.id !== currentUserId) {
                    setCurrentUserId(currentUserId);

                    if (!history.location.pathname.includes('/app')) {
                        if (!!sess?.user) {
                            console.log('login event, routing to profile page, from', history.location.pathname);
                            history.push(routes.AUTHED.USER_PROFILE_PAGE.url);
                        }
                    }
                }
            } else if (event === 'SIGNED_OUT') {
                history.push(routes.PUBLIC.SIGNUP_PAGE.url);
            }

            setCurrentUserId(sess?.user.id ?? null);
        });

        return () => sub.data.subscription.unsubscribe();
    }, [currentUserId, history, setCurrentUserId]);

    useEffect(() => {
        rudderAnalytics.page('Page Viewed', location.pathname);
    }, [location.pathname]);

    if (!devAuthed && !import.meta.env.VITE_APP_BYPASS_AUTH) {
        return (
            <div>
                <input type="password" ref={passwordRef} placeholder="password" />
                <button onClick={login}>log in</button>
            </div>
        );
    }

    console.log(import.meta.env);

    return (
        <ThemeProvider theme={lightTheme}>
            <GlobalStyles />
            {isUserAuthed ? <Authed /> : <Public />}
        </ThemeProvider>
    );
}
